
/* eslint-disable */
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonReorder,
  IonReorderGroup,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import axios from "axios";
import qs from "qs";
import { useRoute } from "vue-router";
import { useIonRouter } from "@ionic/vue";

export default defineComponent({
  name: "ProjectSideBar",
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenu,
    IonMenuToggle,
    IonList,
    IonReorder,
    IonReorderGroup,
  },
  setup() {
    const selectedIndex = ref(0);
    const tools = ref<{ id: number; order: number }[]>([]);

    const components = ref([]);
    const route = useRoute();
    const ionRouter = useIonRouter();
    const list = {} as any;
    //const element = {} as any;

    const handleFrontReorder = (event: CustomEvent) => {
      console.log(1);
      event.detail.complete();
    };
    const handleReorder = (event: CustomEvent) => {
      // The `from` and `to` properties contain the index of the item
      // when the drag started and ended, respectively

      const schluesselMitWertEins = Object.keys(list).find(function (
        schluessel
      ) {
        return list[schluessel] == event.detail.from.toString();
      });

      if (schluesselMitWertEins) {
        if (Number(event.detail.to) < Number(event.detail.from)) {
          console.log(
            "Der Schlüssel mit dem Wert " +
              event.detail.from +
              " ist: " +
              schluesselMitWertEins
          );
          for (const [key, value] of Object.entries(list)) {
            console.log(
              `${key}: ${value}, From: ${event.detail.from}, To: ${event.detail.to}`
            );
            if (value == event.detail.to) {
              console.log(key);
              list[key] = (event.detail.to + 1).toString();
            } else if (
              (value as number) > event.detail.to &&
              (value as number) < event.detail.from
            ) {
              console.log("yes");
              list[key] = (Number(value) + 1).toString();
            }
          }
          list[schluesselMitWertEins] = event.detail.to.toString();

          console.log(list);
          axios.post(
            "https://alex.polan.sk/control-center/test.php",
            qs.stringify({ list: JSON.stringify(list) })
          );
        } else {
          // console.log("Der Schlüssel mit dem Wert " + event.detail.from +" ist: " + schluesselMitWertEins);
          for (const [key, value] of Object.entries(list)) {
            //console.log(`${key}: ${value}, From: ${event.detail.from}, To: ${event.detail.to}`);
            if (value == event.detail.to) {
              //console.log(key);
              list[key] = (event.detail.to - 1).toString();
            } else if (
              (value as number) > event.detail.from &&
              (value as number) < event.detail.to
            ) {
              //console.log("yes");
              list[key] = (Number(value) - 1).toString();
            }
          }
          list[schluesselMitWertEins] = event.detail.to.toString();

          console.log(list);
          axios.post(
            "https://alex.polan.sk/control-center/test.php",
            qs.stringify({ list: JSON.stringify(list) })
          );
        }
      } else {
        console.log(
          "Es gibt keinen Schlüssel mit dem Wert " + event.detail.from + "."
        );
      }
      console.log(
        "Dragged from index",
        event.detail.from,
        "to",
        event.detail.to
      );

      // Finish the reorder and position the item in the DOM based on
      // where the gesture ended. This method can also be called directly
      // by the reorder group
      event.detail.complete();
    };

    axios
      .get(
        "https://alex.polan.sk/control-center/sidebar.php?getSideBarByProjectName=" +
          route.params.project
      )
      .then((response) => {
        tools.value = response.data.tools;
        components.value = response.data.components;
        //console.log(components);
        tools.value.forEach((element) => {
          // console.log(element.id);

          list[element.id] = element.order;
        });
        //console.log(list);
      });
    // console.log(tools);

    function goToConfig(route: string) {
      //console.log("Go to settings");
      ionRouter.push(route);
    }

    return {
      tools: tools,
      selectedIndex,
      components: components,
      goToConfig,
      handleReorder,
      handleFrontReorder,
    };
  },
  created() {
    this.emitter.on("updateSidebar", () => {
      axios
        .get(
          "https://alex.polan.sk/control-center/sidebar.php?getSideBarByProjectName=" +
            this.$route.params.project
        )
        .then((response) => {
          this.tools = response.data.tools;
          this.components = response.data.components;
        });
    });
  },
});
