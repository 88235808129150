
import axios from "axios";
import qs from "qs";
import {
  IonButton,
  IonLabel,
  IonItem,
  IonRow,
  IonCol,
  IonContent,
  IonList,
  IonInput,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import AlertMessage from "@/components/AlertMessage.vue";

GoogleAuth.initialize({
  clientId:
    "706582238302-k3e6bqv81en6u97gf8l5pq883p773236.apps.googleusercontent.com",
  scopes: ["profile", "email"],
  grantOfflineAccess: true,
});

if (localStorage.getItem("token")) {
  //location.href = "/home";
}

interface VerificationData {
  verification_email: string;
  verification_name: string;
  verification_token: string;
}

export default defineComponent({
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      createPasswordView: false,
      user: {
        email: "",
        givenName: "",
        familyName: "",
        imageUrl: "",
      },
      g_password: "",
      g_confirmPassword: "",
    };
  },
  components: {
    IonButton,
    IonLabel,
    IonItem,
    IonRow,
    IonCol,
    IonContent,
    IonList,
    IonInput,
    AlertMessage,
  },
  methods: {
    async continueWithGoogle() {
      this.user = await GoogleAuth.signIn();

      await axios
        .post(
          "/control-center/user.php",
          qs.stringify({
            checkEmailExists: "checkEmailExists",
            email: this.user.email,
          })
        )
        .then((res) => {
          if (res.data.value == true) {
            axios
              .post(
                "/control-center/login.php",
                qs.stringify({
                  email: this.user.email,
                  loginWithGoogle: "loginWithGoogle",
                })
              )
              .then(
                (res) => {
                  console.log(res.data);
                  if (res.data.token) {
                    localStorage.setItem("token", res.data.token);
                    location.href = "/";
                  } else if (res.data.errorMessage) {
                    this.errorMessage = res.data.errorMessage;
                  } else if (res.data.command) {
                    if (res.data.command == "verify-ip") {
                      this.verifyIP(res.data);
                    }
                    this.errorMessage = "send";
                  }
                },
                (err) => {
                  console.log(err);
                  this.errorMessage = "Cannot connect to server";
                }
              );
          } else if (res.data.value == false) {
            this.createPasswordView = true;
          }
        });
    },
    async onSignUp() {
      if (
        this.g_password.trim() === "" ||
        this.g_confirmPassword.trim() === ""
      ) {
        this.errorMessage = "Please fill in all required fields.";
        return;
      }

      if (this.g_password !== this.g_confirmPassword) {
        this.errorMessage = "Passwords do not match.";
        return;
      }

      axios
        .post(
          "/control-center/sign_up.php",
          qs.stringify({
            first_name: this.user.givenName,
            last_name: this.user.familyName,
            profile_img: this.user.imageUrl.replace("s96", "s512"),
            email_adress: this.user.email,
            password: this.g_password,
            login_with_google: true,
          })
        )
        .then((res) => {
          if (res.data.token) {
            localStorage.setItem("token", res.data.token);
            location.href = "/pending-verification";
          } else {
            this.errorMessage =
              "Sorry, an error occurred. Please try again later.";
          }
        });
    },
    async onLogin() {
      if (this.username == "") {
        this.errorMessage = "Field Username mustn't be empty.";
      } else if (this.username.length < 3 && this.username != "") {
        this.errorMessage = "Username to short";
      } else {
        await axios
          .post(
            "/control-center/login.php",
            qs.stringify({ email: this.username, password: this.password })
          )
          .then(
            (res) => {
              if (res.data.token) {
                localStorage.setItem("token", res.data.token);
                console.log(localStorage.getItem("token"));
                location.href = "/";
              } else if (res.data.errorMessage) {
                this.errorMessage = res.data.errorMessage;
              } else if (res.data.command) {
                if (res.data.command == "verify-ip") {
                  this.verifyIP(res.data);
                }
                this.errorMessage = "send";
              }
            },
            (err) => {
              console.log(err);
              this.errorMessage = "Cannot connect to server";
            }
          );
      }
    },
    verifyIP(data: VerificationData) {
      localStorage.setItem("verification_email", data.verification_email);
      localStorage.setItem("verification_name", data.verification_name);
      localStorage.setItem("verification_token", data.verification_token);
      location.href = "/login/verification/";
    },
  },
});
