import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f2de3af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_reorder = _resolveComponent("ion-reorder")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_reorder_group = _resolveComponent("ion-reorder-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    "content-id": "main-content",
    class: "ion-menu",
    type: "overlay"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { id: "inbox-list" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_reorder_group, {
                disabled: false,
                onIonItemReorder: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleReorder($event)))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tools, (p, i) => {
                    return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                      "auto-hide": "false",
                      key: i
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          onDblclick: ($event: any) => (
                _ctx.goToConfig(
                  '/project/' +
                    _ctx.$route.params.project +
                    '/' +
                    p.name
                      .toLowerCase()
                      .replaceAll(' ', '-')
                      .replaceAll('ä', 'a')
                      .replaceAll('Ä', 'a')
                      .replaceAll('ö', 'o')
                      .replaceAll('Ö', 'o')
                      .replaceAll('Ü', 'u')
                      .replaceAll('ü', 'u') +
                    '/config'
                )
              ),
                          onClick: ($event: any) => (this.selectedIndex = i),
                          lines: "none",
                          detail: "false",
                          "router-link": 
                p.icon == 'bar-chart-outline'
                  ? '/project/' +
                    _ctx.$route.params.project +
                    '/dashboard/' +
                    p.name
                      .toLowerCase()
                      .replaceAll(' ', '-')
                      .replaceAll('ä', 'a')
                      .replaceAll('Ä', 'a')
                      .replaceAll('ö', 'o')
                      .replaceAll('Ö', 'o')
                      .replaceAll('Ü', 'u')
                      .replaceAll('ü', 'u')
                  : '/project/' +
                    _ctx.$route.params.project +
                    '/' +
                    p.name
                      .toLowerCase()
                      .replaceAll(' ', '-')
                      .replaceAll('ä', 'a')
                      .replaceAll('Ä', 'a')
                      .replaceAll('ö', 'o')
                      .replaceAll('Ö', 'o')
                      .replaceAll('Ü', 'u')
                      .replaceAll('ü', 'u')
              ,
                          class: _normalizeClass(["hydrated menu-item", { selected: this.selectedIndex === i }])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              name: p.icon
                            }, null, 8, ["name"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(p.name[0].toUpperCase()) + _toDisplayString(p.name.substring(1)), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_reorder, { slot: "end" }, {
                              default: _withCtx(() => [
                                (p.hasConfig == 1)
                                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                                      key: 0,
                                      style: {"cursor":"pointer","z-index":"1000"},
                                      name: "cog-outline"
                                    }))
                                  : (_openBlock(), _createElementBlock("pre", _hoisted_1))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onDblclick", "onClick", "router-link", "class"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _createVNode(_component_ion_menu_toggle, {
                    "auto-hide": "false",
                    style: {"margin-top":"1rem !important"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        detail: "false",
                        class: "new-tool",
                        "router-link": '/project/' + _ctx.$route.params.project + '/new-tool/'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "start",
                            name: "add"
                          }),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("New Tool")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["router-link"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, { id: "inbox-list" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_reorder_group, {
                disabled: false,
                onIonItemReorder: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleFrontReorder($event)))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (p, i) => {
                    return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                      "auto-hide": "false",
                      key: i
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          onDblclick: ($event: any) => (
                _ctx.goToConfig(
                  '/project/components/' +
                    _ctx.$route.params.project +
                    '/' +
                    p.name
                      .toLowerCase()
                      .replaceAll(' ', '-')
                      .replaceAll('ä', 'a')
                      .replaceAll('Ä', 'a')
                      .replaceAll('ö', 'o')
                      .replaceAll('Ö', 'o')
                      .replaceAll('Ü', 'u')
                      .replaceAll('ü', 'u') +
                    '/config'
                )
              ),
                          onClick: ($event: any) => (this.selectedIndex = Number(i) + Number(_ctx.tools.length)),
                          lines: "none",
                          detail: "false",
                          "router-link": 
                '/project/' +
                _ctx.$route.params.project +
                '/components/' +
                p.name
                  .toLowerCase()
                  .replaceAll(' ', '-')
                  .replaceAll('ä', 'a')
                  .replaceAll('Ä', 'a')
                  .replaceAll('ö', 'o')
                  .replaceAll('Ö', 'o')
                  .replaceAll('Ü', 'u')
                  .replaceAll('ü', 'u')
              ,
                          class: _normalizeClass(["hydrated menu-item", {
                selected:
                  this.selectedIndex === Number(i) + Number(_ctx.tools.length),
              }])
                        }, {
                          default: _withCtx(() => [
                            (p.type == 'script')
                              ? (_openBlock(), _createBlock(_component_ion_icon, {
                                  key: 0,
                                  slot: "start",
                                  name: "code-slash-outline"
                                }))
                              : _createCommentVNode("", true),
                            (p.type == 'image')
                              ? (_openBlock(), _createBlock(_component_ion_icon, {
                                  key: 1,
                                  slot: "start",
                                  name: "image-outline"
                                }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(p.name[0].toUpperCase()) + _toDisplayString(p.name.substring(1)), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_reorder, { slot: "end" }, {
                              default: _withCtx(() => [
                                (p.hasConfig == 1)
                                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                                      key: 0,
                                      style: {"cursor":"pointer","z-index":"1000"},
                                      name: "cog-outline"
                                    }))
                                  : (_openBlock(), _createElementBlock("pre", _hoisted_2))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onDblclick", "onClick", "router-link", "class"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _createVNode(_component_ion_menu_toggle, {
                    "auto-hide": "false",
                    style: {"margin-top":"1rem !important"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        detail: "false",
                        class: "new-tool",
                        "router-link": 
                '/project/' + _ctx.$route.params.project + '/new/component'
              
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "start",
                            name: "add"
                          }),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("New Component")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["router-link"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}